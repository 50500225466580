import { format } from 'date-fns'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import * as Yup from 'yup'
import './App.css'
import './reset.css'
import api from './useApiCall'

export default function App() {
  const [council, setCouncil] = useState()
  const [gender, setGender] = useState()
  const [base64Image, setBase64Image] = useState('')
  const [formValues, setFormValues] = useState({
    council: '',
    gender: '',
    name: '',
    lastName: '',
    id: '',
    address: '',
    city: '',
    state: '',
    postanIndx: '',
    phone: '',
    email: '',
    entry: '',
    birthDate: '',
    reason: '',
    photo: '',
  })

  const addBlogValidation = Yup.object().shape({
    // Add validation schema if needed
  })

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result
          .replace('data:', '')
          .replace(/^.+,/, '')
        setBase64Image(base64String)
        setFieldValue('photo', base64String)
        setFormValues((prevValues) => ({
          ...prevValues,
          photo: base64String,
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {}, [formValues])

  const convertToUnixTimestamp = (dateStr) => {
    if (!dateStr) return null

    const [month, day, year] = dateStr.split('/').map(Number)

    const date = new Date(year, month - 1, day)

    if (isNaN(date.getTime())) return null

    const unixTimestamp = Math.floor(date.getTime() / 1000)

    return unixTimestamp
  }

  const submitRegistration = async (val) => {
    try {
      const url = 'https://www.amteczone.com/rest/api/Electorat/AddNewElectorat'
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          first_name: val?.name,
          last_name: val?.lastName,
          state: val?.state,
          consule: val?.council,
          image: val?.photo,
          idnumber: val?.id,
          birthdate: convertToUnixTimestamp(val?.birthDate),
          sex: val?.gender,
          email: val?.email,
          arrivedate: convertToUnixTimestamp(val?.entry),
          visitreason: val?.reason,
          address: val?.address,
          city: val?.city,
          zipcode: val?.postanIndx,
          phone: val?.phone,
        }),
      }
      const responseData = await api.fetchData(url, options)

      if (responseData.status == 'success') {
        alert('წარმატებით მოხდა რესგისტრაცია !')
        window.location.reload()
      } else {
        alert('რესგისტრაცია წარუმატებელია!')
        window.location.reload()
      }
    } catch (error) {}
  }

  return (
    <div className="App">
      <div className="page_container">
        <p className="formHeader">საკონსულო აღრიცხვა</p>
        <Formik
          validationSchema={addBlogValidation}
          initialValues={formValues}
          onSubmit={(values) => {
            submitRegistration(values)
          }}
          enableReinitialize
        >
          {(props) => {
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  props.handleSubmit()
                  setFormValues(props.values)
                }}
                className="add_form"
              >
                <div className="checkBoxes">
                  <label className="formLabelBlack" htmlFor="lastName">
                    საკონსულო
                  </label>
                  <label>
                    <input
                      name="council"
                      value="ქ. ნიუ-იორკში საქართველოს გენერალური საკონსულო"
                      type="checkbox"
                      checked={
                        council ===
                        'ქ. ნიუ-იორკში საქართველოს გენერალური საკონსულო'
                      }
                      onChange={() => {
                        setCouncil(
                          'ქ. ნიუ-იორკში საქართველოს გენერალური საკონსულო',
                        )
                        props.setFieldValue(
                          'council',
                          'ქ. ნიუ-იორკში საქართველოს გენერალური საკონსულო',
                        )
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          council:
                            'ქ. ნიუ-იორკში საქართველოს გენერალური საკონსულო',
                        }))
                      }}
                    />
                    ქ. ნიუ-იორკში საქართველოს გენერალური საკონსულო
                  </label>
                  <label>
                    <input
                      name="council"
                      value="ქ. სან-ფრანცისკოში საქართველოს გენერალური საკონსულო"
                      type="checkbox"
                      checked={
                        council ===
                        'ქ. სან-ფრანცისკოში საქართველოს გენერალური საკონსულო'
                      }
                      onChange={() => {
                        setCouncil(
                          'ქ. სან-ფრანცისკოში საქართველოს გენერალური საკონსულო',
                        )
                        props.setFieldValue(
                          'council',
                          'ქ. სან-ფრანცისკოში საქართველოს გენერალური საკონსულო',
                        )
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          council:
                            'ქ. სან-ფრანცისკოში საქართველოს გენერალური საკონსულო',
                        }))
                      }}
                    />
                    ქ. სან-ფრანცისკოში საქართველოს გენერალური საკონსულო
                  </label>
                  <label>
                    <input
                      name="council"
                      value="ამერიკის შეერთებულ შტატებში საქართველოს საელჩო"
                      type="checkbox"
                      checked={
                        council ===
                        'ამერიკის შეერთებულ შტატებში საქართველოს საელჩო'
                      }
                      onChange={() => {
                        setCouncil(
                          'ამერიკის შეერთებულ შტატებში საქართველოს საელჩო',
                        )
                        props.setFieldValue(
                          'council',
                          'ამერიკის შეერთებულ შტატებში საქართველოს საელჩო',
                        )
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          council:
                            'ამერიკის შეერთებულ შტატებში საქართველოს საელჩო',
                        }))
                      }}
                    />
                    ამერიკის შეერთებულ შტატებში საქართველოს საელჩო
                  </label>
                </div>
                <div className="checkBoxes2">
                  <label className="formLabelBlack" htmlFor="lastName">
                    სქესი
                  </label>
                  <label>
                    <input
                      name="gender"
                      value="1"
                      type="checkbox"
                      checked={gender === '1'}
                      onChange={() => {
                        setGender('1')
                        props.setFieldValue('gender', '1')
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          gender: '1',
                        }))
                      }}
                    />
                    მდედრობითი
                  </label>
                  <label>
                    <input
                      name="gender"
                      value="0"
                      type="checkbox"
                      checked={gender === '0'}
                      onChange={() => {
                        setGender('0')
                        props.setFieldValue('gender', '0')
                        setFormValues((prevValues) => ({
                          ...prevValues,
                          gender: '0',
                        }))
                      }}
                    />
                    მამრობითი
                  </label>
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="name">
                    სახელი ქართულად
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={props.values.name}
                    onChange={(e) => {
                      props.handleChange(e)
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        name: e.target.value,
                      }))
                    }}
                    onBlur={props.handleBlur}
                    placeholder={'სახელი ქართულად'}
                    className="formAddInput"
                    required
                  />
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="lastName">
                    გვარი ქართულად
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    type="text"
                    value={props.values.lastName}
                    onChange={(e) => {
                      props.handleChange(e)
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        lastName: e.target.value,
                      }))
                    }}
                    onBlur={props.handleBlur}
                    placeholder={'გვარი ქართულად'}
                    className="formAddInput"
                    required
                  />
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="id">
                    პირადი ნომერი
                  </label>
                  <input
                    id="id"
                    name="id"
                    type="text"
                    value={props.values.id}
                    onChange={(e) => {
                      props.handleChange(e)
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        id: e.target.value,
                      }))
                    }}
                    onBlur={props.handleBlur}
                    placeholder={'პირადი ნომერი'}
                    className="formAddInput"
                    required
                  />
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="address">
                    ამჟამინდელი მისამართი
                  </label>
                  <input
                    id="address"
                    name="address"
                    type="text"
                    value={props.values.address}
                    onChange={(e) => {
                      props.handleChange(e)
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        address: e.target.value,
                      }))
                    }}
                    onBlur={props.handleBlur}
                    placeholder={'ამჟამინდელი მისამართი'}
                    className="formAddInput"
                    required
                  />
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="city">
                    ქალაქი
                  </label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    value={props.values.city}
                    onChange={(e) => {
                      props.handleChange(e)
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        city: e.target.value,
                      }))
                    }}
                    onBlur={props.handleBlur}
                    placeholder={'ქალაქი'}
                    className="formAddInput"
                    required
                  />
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="state">
                    შტატი
                  </label>
                  <input
                    id="state"
                    name="state"
                    type="text"
                    value={props.values.state}
                    onChange={(e) => {
                      props.handleChange(e)
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        state: e.target.value,
                      }))
                    }}
                    onBlur={props.handleBlur}
                    placeholder={'შტატი'}
                    className="formAddInput"
                    required
                  />
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="postanIndx">
                    საფოსტო ინდექსი
                  </label>
                  <input
                    id="postanIndx"
                    name="postanIndx"
                    type="text"
                    value={props.values.postanIndx}
                    onChange={(e) => {
                      props.handleChange(e)
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        postanIndx: e.target.value,
                      }))
                    }}
                    onBlur={props.handleBlur}
                    placeholder={'საფოსტო ინდექსი'}
                    className="formAddInput"
                    required
                  />
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="phone">
                    ტელეფონი
                  </label>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    value={props.values.phone}
                    onChange={(e) => {
                      props.handleChange(e)
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        phone: e.target.value,
                      }))
                    }}
                    onBlur={props.handleBlur}
                    placeholder={'ტელეფონი'}
                    className="formAddInput"
                    required
                  />
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="email">
                    ელ-ფოსტა
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="text"
                    value={props.values.email}
                    onChange={(e) => {
                      props.handleChange(e)
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        email: e.target.value,
                      }))
                    }}
                    onBlur={props.handleBlur}
                    placeholder={'ელ-ფოსტა'}
                    className="formAddInput"
                    required
                  />
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="date">
                    ქვეყანაში პირველად შესვლის თარიღი (თვე, დღე, წელი) *
                  </label>
                  <DatePicker
                    id="date"
                    name="entry"
                    selected={
                      props.values.entry ? new Date(props.values.entry) : null
                    }
                    onChange={(entry) => {
                      props.setFieldValue('entry', format(entry, 'MM/dd/yyyy'))
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        entry: format(entry, 'MM/dd/yyyy'),
                      }))
                    }}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    className="formAddInput"
                    required
                  />
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="date">
                    დაბადების თარიღი (თვე, დღე, წელი)*
                  </label>
                  <DatePicker
                    id="date"
                    name="birthDate"
                    selected={
                      props.values.birthDate
                        ? new Date(props.values.birthDate)
                        : null
                    }
                    onChange={(birthDate) => {
                      props.setFieldValue(
                        'birthDate',
                        format(birthDate, 'MM/dd/yyyy'),
                      )
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        birthDate: format(birthDate, 'MM/dd/yyyy'),
                      }))
                    }}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    className="formAddInput"
                    required
                  />
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="reason">
                    ვიზიტის მიზანი*
                  </label>
                  <select
                    id="type"
                    name="reason"
                    value={props.values.reason}
                    onChange={(e) => {
                      props.handleChange(e)
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        reason: e.target.value,
                      }))
                    }}
                    onBlur={props.handleBlur}
                    className="formAddInput"
                    required
                  >
                    <option value="" label="აირჩიეთ ტიპი" />
                    <option value="ტურიზმი" label="ტურიზმი" />
                    <option value="სწავლა" label="სწავლა" />
                    <option value="სამუშაო" label="სამუშაო" />
                    <option value="მწვანე ბარათი" label="მწვანე ბარათი" />
                    <option value="მკურნალობა" label="მკურნალობა" />
                    <option value="სხვა" label="სხვა" />
                  </select>
                </div>
                <div className="form-inp-cont">
                  <label className="formLabelBlack" htmlFor="photo">
                    ატვირთეთ ფოტო (მაქს.1, ზომა 3X5)
                  </label>
                  <input
                    id="photo"
                    name="photo"
                    type="file"
                    onChange={(event) =>
                      handleFileChange(event, props.setFieldValue)
                    }
                    accept="image/*"
                    className="user-input"
                    required
                  />
                </div>
                <div className="add-btn-container">
                  <button className="add-btn" type="submit">
                    დასრულება
                  </button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
